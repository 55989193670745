import React from "react"
import { connect, useSelector } from "react-redux"
import { ExchangeTradedFuturesQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import ContentBasic from "../../components/content-basic"

const ExchangeTradedFutures = () => {
  const language = useSelector(state => state.language)
  const exchangeTradedFuturesData = ExchangeTradedFuturesQuery(language)
  return (
    <Layout>
      <ContentBasic {...exchangeTradedFuturesData} />
    </Layout>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(ExchangeTradedFutures)
